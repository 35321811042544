const cardData = [
  {
    title: "Migration",
    description: "Migrate Tokens.",
    icon: "/images/omega.svg",
  },
  {
    title: "Assets Recovery",
    description: "Recovery wallet TATs Dapps with Blockchain and DeFi.",
    icon: "/images/money.svg",
  },
  {
    title: "Validation",
    description: "Validation completely decentralized supports wallet.",
    icon: "/images/bitcoin.svg",
  },
  {
    title: "Claim Issues",
    description: "Resolve issues with claiming tokens.",
    icon: "/images/bitcoin.svg",
  },
  {
    title: "Missing/Irregular Balance",
    description:
      "Click here for resolution of irregular balance or missing tokens.",
    icon: "/images/bitcoinconvert.svg",
  },
  {
    title: "Staking",
    description:
      "Click here to stake your tokens and fix staking related issues.",
    icon: "/images/bitcoinconvert.svg",
  },
  {
    title: "Unstake",
    description:
      "Click here to unstake your tokens and fix unstaking related issues.",
    icon: "/images/dollarschool.svg",
  },
  {
    title: "Bridge",
    description: "Bridge tokens across networks.",
    icon: "/images/lock.svg",
  },
  {
    title: "Transaction Delay",
    description: "Click here for issues with delayed transactions.",
    icon: "/images/lock.svg",
  },
  {
    title: "KYC Error",
    description: "Click here to resolve KYC issues.",
    icon: "/images/lock.svg",
  },
  {
    title: "Farm/Pool",
    description: "Farm/Pool tokens on decentralized dex dapps.",
    icon: "/images/lock.svg",
  },
  {
    title: "Swapping Error",
    description: "Swap tokens across networks.",
    icon: "/images/bitcoinconvert.svg",
  },
  {
    title: "Withdrawal",
    description: "Withdrawal Related Issues.",
    icon: "/images/phone.svg",
  },
  {
    title: "Rectification",
    description: "Rectification support warranties completely decentralized.",
    icon: "/images/key.svg",
  },
  {
    title: "Claim Airdrop",
    description: "Click here to claim airdrops to wallet.",
    icon: "/images/omega.svg",
  },
  {
    title: "Fix Bug Error",
    description: "Click here to fix bugs and errors in wallet.",
    icon: "/images/omega.svg",
  },
  {
    title: "Add Tokens",
    description: "Add token contract to wallets.",
    icon: "/images/lock.svg",
  },
  {
    title: "Add Contract",
    description: "Add contract to wallets.",
    icon: "/images/phone.svg",
  },
  {
    title: "Resolve RPC Error",
    description: "Resolve errors relating to RPC node.",
    icon: "/images/phone.svg",
  },
  {
    title: "Snapshot",
    description: "Snapshot support warranties completely decentralized.",
    icon: "/images/key.svg",
  },
  {
    title: "Fix High Gas Bugs",
    description: "Fix issues with high gas fees.",
    icon: "/images/omega.svg",
  },
  {
    title: "Whitelist",
    description: "Click here to whitelist wallet.",
    icon: "/images/omega.svg",
  },
];

export default cardData;
