import React from "react";
import { Link } from "react-router-dom";

function Layout({ children }) {
  return (
    <div>
      <header className="bg-[#141416] min-h-[64px] border-b border-white/10  z-30 w-full text-white shadow">
        <div className="container mx-auto flex justify-between items-center py-4 px-6">
          <Link to="/">
            <img src="/images/logo.png" className="w-40" alt="" />
          </Link>
          <nav className="flex flex-row items-center space-x-4">
            <Link
              to="/about"
              className=" text-white text-base font-medium font-sans  hover:text-gray-200"
            >
              Documentation
            </Link>
            <Link
              to="/contact"
              className=" text-white text-base font-medium font-sans  hover:text-gray-200"
            >
              Medium
            </Link>
            <Link
              to="/contact"
              className=" text-white text-base font-medium font-sans  hover:text-gray-200"
            >
              Discord
            </Link>
            <br />
            <button className=" border-none outline-none rounded-md cursor-pointer p-[2px] text-white bg-gradient-to-br from-blue-600 via-purple-600 to-purple-500 flex flex-col items-center justify-center ">
              <span className="px-8 py-3 text-sm font-medium capitalize border-none rounded-md bg-neutral-900">
                Launch App
              </span>
            </button>
          </nav>
        </div>
      </header>
      <main>{children}</main>
      <footer className="w-full relative flex flex-col items-center justify-center pt-4">
        <div className="flex flex-col gap-8 justify-between items-center my-8">
          <h3 className=" text-3xl font-bold text-white text-center ">
            Join Our Journey{" "}
          </h3>
          <p className=" text-base font-nromal  text-white text-center ">
            Help us build the future of leverage trading. Connect with us:{" "}
          </p>
          <div className="flex flex-row gap-8 items-center justify-center">
            <button className="w-fit min-w-20 min-h-20 p-4 flex flex-col items-center justify-center bg-slate-700 rounded-full">
              <img
                src="/images/discord.svg"
                className=" fill-white h-4/5 w-4/5 "
                alt="discord"
              />
            </button>
            <button className="w-fit min-w-20 min-h-20 p-4 flex flex-col items-center justify-center bg-slate-700 rounded-full">
              <img
                src="/images/twt.svg"
                className=" fill-white h-4/5 w-4/5 "
                alt="twt"
              />
            </button>
          </div>
          <img
            src="/images/pyth.svg"
            className="stroke-white max-w-[150px] fill-white"
            alt="Pyth "
          />
        </div>

        <div className="flex w-full relative min-h-10 flex-row items-center justify-between  bg-gray-800 text-center">
          <div></div>
          <div className=" flex-row flex gap-5   ">
            <p className="w-fit inline text-gray-400 ">Discord </p>
            <p className="w-fit inline text-gray-400 ">Twitter </p>
            <p className="w-fit inline text-gray-400 ">Telegram </p>
            <p className="w-fit inline text-gray-400 ">&copy; 2024</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Layout;
