// /src/components/RainbowCard.jsx
import React from "react";
import "./RainbowButton.css";

const RainbowCard = ({ title, description, icon }) => {

  return (
    <>
      <div
        className="rainbow-button relative min-w-72 min-h-80 rounded-lg shadow-lg bg-[#18191d] overflow-hidden group"
      >
        {/* Card Content */}
        <div className="relative z-10 flex flex-col self-start items-start justify-start w-full h-full text-center">
          <img
            src={icon}
            alt={`${title} icon`}
            className="w-24 h-24 mb-4 object-contain"
          />
          <div className="flex flex-col w-fit max-w-60 items-center mx-auto justify-between gap-4 ">
            <h3 className="text-white text-center text-xl font-bold font-sans uppercase mb-2">
              {title}
            </h3>
            <p className="text-white font-normal text-center font-sans uppercase text-base">
              {description}
            </p>
          </div>
        </div>
      </div>
     
    </>
  );
};

export default RainbowCard;
