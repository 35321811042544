import React from "react";

// About Page Component
function About() {
  return (
    <div>
      <h2 className="text-3xl font-bold mb-4">About Us</h2>
      <p>
        We specialize in asset recovery and management for businesses and
        individuals.
      </p>
    </div>
  );
}

export default About;
