import React from "react";
import { FaCircleQuestion, FaX } from "react-icons/fa6";

function Modal({ isToggled, onToggle }) {

  const toggleMenu = () => {
    onToggle(!isToggled)
  };
  var switcher = false
  const handSwitch = () =>{
    switcher = true
  }


  return (
    <div className="connect max-w-[360px] rounded-3xl mx-auto p-4  left-7 sm:left-1/3 w-full bg-white">
      <div className="flex flex-row items-center justify-between mb-4 ">
        <FaCircleQuestion />
        <h4>Connect Wallet </h4>
        <FaX onClick={toggleMenu} />
      </div>
      <hr />
      {switcher ? (<>
        <div className="text-center mb-6">
          <p className="mt-2 text-sm text-gray-600">
            For security reasons, confirm access to your wallet using a secret recovery phrase.
          </p>
        </div>

        <form>
          <div className="grid grid-cols-2 gap-4">
            {Array.from({ length: 12 }).map((_, index) => (
              <input
                key={index}
                type="text"
                placeholder={`Word #${index + 1}`}
                className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            ))}
          </div>
          <button
            type="submit"
            className="mt-6 w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
            Import wallet group
          </button>
        </form>
            </>):(  <div>
        <button onClick={handSwitch} className="w-full h-fit p-2 flex flex-row justify-between items-start">
          <div className="flex flex-row gap-4 items-center w-fit justify-start">
            <img
              className=" h-10 w-10 rounded-sm  "
              src="/images/Walletconnect-logo.png"
              alt=""
            />
            <p className="text-base text-black font-normal">WalletConnect </p>
          </div>
        </button>
        <button onClick={handSwitch} className="w-full h-fit p-2 flex flex-row justify-between items-start">
          <div className="flex flex-row gap-4 items-center w-fit justify-start">
            <img
              className=" h-10 w-10 rounded-lg  "
              src="/images/metamask.png"
              alt=""
            />
            <p className="text-base text-black font-normal">MetaMask </p>
          </div>
        </button>
        <button onClick={handSwitch} className="w-full h-fit p-2 flex flex-row justify-between items-start">
          <div className="flex flex-row gap-4 items-center w-fit justify-start">
            <img
              className=" h-10 w-10 rounded-xl  "
              src="/images/trust.jpg"
              alt=""
            />
            <p className="text-base text-black font-normal">Trust Wallet </p>
          </div>
        </button>
        <button onClick={handSwitch} className="w-full h-fit p-2 flex flex-row justify-between items-start">
          <div className="flex flex-row gap-4 items-center w-fit justify-start">
            <img
              className=" h-10 w-10 rounded-xl  "
              src="/images/exodus.svg"
              alt=""
            />
            <p className="text-base text-black font-normal">Exodus </p>
          </div>
        </button>
        <button onClick={handSwitch} className="w-full h-fit p-2 flex flex-row justify-between items-start">
          <div className="flex flex-row gap-4 items-center w-fit justify-start">
            <img
              className=" h-10 w-10 rounded-xl  "
              src="/images/coinbase.png"
              alt=""
            />
            <p className="text-base text-black font-normal">Coinbase </p>
          </div>
        </button>
        <button onClick={handSwitch} className="w-full h-fit p-2 flex flex-row justify-between items-start">
          <div className="flex flex-row gap-4 items-center w-fit justify-start">
            <img className=" h-10 w-10 rounded-xl  " src="" alt="" />
            <p className="text-base text-black font-normal">All Wallets</p>
          </div>
        </button>
      </div>
    ) }
    </div>
  );
}




export default Modal;
