import React from "react";

function Hero() {
  return (
    <div className=" sm:pb-12 sm:px-24  px-5  ">
      <div className="flex flex-col sm:pt-32 sm:gap-9 gap-5 pt-6 place-items-stretch max-w-xl items-start justify-between ">
        <h1 className="  font-medium sm:text-6xl text-2xl font-sans text-white ">
          Welcome to the future of open decentralized leveraged trading.
        </h1>
        <p className=" text-gray-50 max-w-96 text-base font-normal  ">
          The first-of-its-kind oracle architecture utilizes Pyth data to
          provide real-time signed asset prices for trading, making it the
          fastest and most reliable on-chain leveraged trading platform.
        </p>
        <div className="flex flex-col gap-0 items-start justify-between">
          <button className="bg-custom-gradient rainbow-button text-white uppercase flex flex-row gap-3 rounded-full w-fit items-center m-4 ">
            Synchronize
          </button>
          <button className="bg-custom-gradient rainbow-button text-white uppercase flex flex-row gap-3 rounded-full w-fit items-center m-4 ">
            connect to exchange
          </button>
        </div>
      </div>
      <div></div>
    </div>
  );
}

export default Hero;
