import React, { useState } from "react";
import RainbowCard from "./components/ui/Cards";
import cardData from "./components/data/carddata"; // Import the data
import Hero from "./components/ui/Hero";
import Modal from "./components/ui/Modal";

function App() {
  const [openMenu, setIsOpenMenu] = useState(false);
  const toggleMenu = () => {
    setIsOpenMenu(!openMenu);
  };

  return (
    <>

      <Hero />

      <div
        onClick={toggleMenu}
        className="grid mx-auto w-fit sm:place-items-end place-items-center gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
        >
        {cardData.map((card, index) => (
          <RainbowCard
          key={index}
          title={card.title}
          description={card.description}
          icon={card.icon}
          />
        ))}
      </div>
      {openMenu ? (
        <div className="flex items-center">
          <Modal isToggled={openMenu}  onToggle={setIsOpenMenu} />
          <div className="grey"></div>
        </div>
      ) : null}
      
    </>
  );
}

export default App;
